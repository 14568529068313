import NotFound from '@/components/NotFound/NotFound';
import useFormatMessage from '@/hooks/useFormatMessage';
import DefaultLayout from '@/layouts/default';

export default function Custom404Page() {
  const formatMessage = useFormatMessage();
  return (
    <DefaultLayout
      showB2BModal={false}
      breadcrumbs={[
        {
          id: 1,
          title: formatMessage('breadcrumbs_home'),
          metadata: {
            id: 1,
            pathname: '/',
          },
        },
        {
          id: 2,
          title: formatMessage('404_breadcrumb'),
          metadata: {
            id: 2,
            pathname: '/404',
          },
        },
      ]}>
      <NotFound />
    </DefaultLayout>
  );
}
