import { Stack } from '@mui/material';
import Image from 'next/image';
import PropTypes from 'prop-types';

import RatingWidget from '@/components/RatingWidget/RatingWidget';
import StrapiImage from '@/components/StrapiImage/StrapiImage';
import Chip from '@/components/ui/Chip/Chip';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import {
  HeadingWrapper,
  ImageWrapperInner,
  ImageWrapperOuter,
} from './Hero.style';

const Hero = ({ headline, pictures, chip, disableRatings = false }) => {
  const mobileMediaQuery = useMobileMediaQuery();

  return (
    <PageMargins sx={{ marginBottom: 6 }}>
      <Stack
        position="relative"
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        mt={2}
        sx={{
          minHeight: [
            100,
            100,
            `${pictures ? '260px' : 120}`,
            `${pictures ? '360px' : 120}`,
          ],
        }}
        gap={1}>
        {chip && (
          <Chip size="small" uppercase>
            {chip}
          </Chip>
        )}
        <HeadingWrapper fullWidth={!pictures}>{headline}</HeadingWrapper>
        {!disableRatings && <RatingWidget />}
        {pictures && (
          <ImageWrapperOuter>
            <ImageWrapperInner>
              {pictures?.attributes ? (
                <StrapiImage
                  data={pictures}
                  fill
                  style={{
                    objectFit: 'contain',
                    objectPosition: `${mobileMediaQuery ? 'center' : 'right'}`,
                  }}
                  sizes="(max-width: 1200px) 100vw, 1200px"
                  priority
                />
              ) : (
                <Image
                  src={pictures}
                  alt=""
                  fill
                  style={{
                    objectFit: 'contain',
                    objectPosition: `${mobileMediaQuery ? 'center' : 'right'}`,
                  }}
                  sizes="(max-width: 1200px) 100vw, 1200px"
                  priority
                />
              )}
            </ImageWrapperInner>
          </ImageWrapperOuter>
        )}
      </Stack>
    </PageMargins>
  );
};

Hero.propTypes = {
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  pictures: PropTypes.object,
  chip: PropTypes.string,
  disableRatings: PropTypes.bool,
};

export default Hero;
