import { Box, Grid, Link as MuiLink, Typography } from '@mui/material';
import Link from 'next/link';
import { useState } from 'react';

import ContactFormDialog from '@/components/ContactFormDialog/ContactFormDialog';
import Hero from '@/components/Hero/Hero';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import useFormatMessage from '@/hooks/useFormatMessage';

import notFound from './lib/not-found.webp';

const NotFound = () => {
  const formatMessage = useFormatMessage();
  const [contactFormOpen, setContactFormOpen] = useState(false);

  const handleOpen = () => setContactFormOpen(true);

  const handleClose = () => setContactFormOpen(false);

  const headlineElement = (
    <Typography component="h1" variant="h1Redesign">
      {formatMessage('404_heading')}
    </Typography>
  );

  return (
    <>
      <Box mb={6}>
        <Hero
          headline={headlineElement}
          pictures={notFound}
          chip={formatMessage('404_tag')}
          disableRatings
        />
        <PageMargins>
          <Grid container spacing={2} item xs={12} mb={12}>
            <Grid item xs={12} md={5}>
              <Typography
                variant="h5Redesign"
                component="h2"
                color="text.secondary"
                sx={{ pr: { md: '20%' } }}>
                {formatMessage('404_subheading')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1Redesign"
                component="p"
                color="text.secondary">
                {formatMessage('404_text', {
                  homepage: chunks => (
                    <MuiLink component={Link} href="/">
                      {chunks}
                    </MuiLink>
                  ),
                  contact: chunks => (
                    <MuiLink
                      component="button"
                      sx={{ verticalAlign: 'baseline' }}
                      onClick={handleOpen}>
                      {chunks}
                    </MuiLink>
                  ),
                })}
              </Typography>
            </Grid>
          </Grid>
        </PageMargins>
      </Box>
      <ContactFormDialog open={contactFormOpen} onClose={handleClose} />
    </>
  );
};

export default NotFound;
